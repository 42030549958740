body {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  color: rgba(0, 0, 0, 0.80);
}
p {
  margin-bottom: 0em !important;
  line-height: 1.5em !important;
}
.ant-form-item label {
  line-height: 1.5em !important;
}
.ant-form-explain {
  padding: 5px;
  background-color: #f5f585;
}
.ant-btn {
  width: 100%;
  margin-bottom: 10px;
}
.ant-row {
  padding-top: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
}
.ant-select {
  width: 100% !important;
}
.ant-radio-inner::after {
  border-radius: 4px;
}
.ant-form-explain {
  font-size: 14px;
}
.ant-modal {
  width: 90% !important;
}
.ant-checkbox-inner, .ant-radio-inner {
  border-color: #585858 !important;
}
.istruzioni {
  font-size: 14px;
  color: rgba(0,0,0,.90);
}
.assistenza {
  font-size: 0.8rem;
  margin-top: 10px;
  margin-bottom: 10px;
}
.cf {
  text-transform: uppercase;
}
.esito_ok {
  padding: 12px;
  background-color: #d3eaca;
}
.esito_ko {
  padding: 12px;
  background-color: #eed4d4;
}
.iframe {
  width: 100%;
  height: 1500px; /*675px;*/
  border: 0;
}
.rosso {
  color: #a72035;
}
.logo-div {
  text-align: center;
  margin-bottom: 30px;
}
.logo-img {
    width: 200px;
}

.bandiera {
  width: 30px;
  margin-bottom: 20px;
  cursor: pointer;
}

.ottimizzato {
  font-size: 14px;
}

@media only screen and (max-width: 500px)
{
  body, p {
    font-size: 14px;
  }
}
